import React from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { makeStyles, Grid, Container, useMediaQuery } from '@material-ui/core'
import Master from '../components/Master'

const company = 'Renata Scarpelli - Design para Festas'

export default function Home() {

  const useStyles = makeStyles(theme => ({
    content: {
      padding: theme.spacing(0, 3, 6),
    },
    imageOnMediaQuery: {
      width: '100%'
    },
    width: {
      width: '100%'
    }
  }));

  const classes = useStyles();
  const image = <img src={`/galinha-pintadinha.jpg`} title={company} alt={company} className={classes.width} />
  // const imageMediaQuery = useMediaQuery('(min-width:373px)');
  // let image = <img src={`https://picsum.photos/200`} title={company} alt={company} className={classes.imageOnMediaQuery} />
  // if (!imageMediaQuery) {
  //   image = <img src={`https://picsum.photos/200`} title={company} alt={company} />
  // }

  return (
    <Master>
      <GatsbySeo
        title={company}
        description={company}
        openGraph={{
          title: `${company}`,
          description: `${company}`,
          images: [
            {
              url: '/renata-scarpelli_512.png',
              width: 512,
              height: 512,
              alt: `${company}`
            }
          ],
          site_name: `${company}`,
        }}
        twitter={{
          handle: '@handle',
          site: '@site',
          cardType: 'summary_large_image',
        }}
      />
      <Container component="main" className={classes.content}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <h2>Muito obrigado!</h2>
            <p>Em alguns instantes você receberá em seu email uma arte em alta definição de painel redondo Galinha Pintadinha!</p>
          </Grid>
          <Grid item xs={12} sm={6}>
            {image}
          </Grid>
        </Grid>
      </Container >
    </Master >
  )
}
